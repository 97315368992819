<template>
    <b-form ref="form" :save-url="addUrl" :save-params="{auction: $route.params.id}"
            @success="formSuccess" v-slot="{model, loading}">

        <h4 class="text-center font-inter-semi-bold mb-5">Add Video</h4>

        <validated-file-input placeholder="" label="Video Thumbnail" class="c-file-input"
                              v-model="model.video_thumbnail" name="Video Thumbnail" :disabled="loading">
            <template #append>
                <div class="form-file-input-tag fl-x-cc text-white px-3">
                    <p class="m-0">Upload</p>
                    <img src="../../../assets/web/dashboard/icons/arrow-up-16.png" alt="">
                </div>
            </template>
        </validated-file-input>

<!--        <custom-video-file-input placeholder="" label="Upload Video" name="Video" class="c-file-input"-->
<!--                                 v-model="model.video" :disabled="loading">-->
<!--            <template #append>-->
<!--                <div class="form-file-input-tag fl-x-cc text-white px-3">-->
<!--                    <p class="m-0">Upload</p>-->
<!--                    <img src="../../../assets/web/dashboard/icons/arrow-up-16.png" alt="">-->
<!--                </div>-->
<!--            </template>-->
<!--        </custom-video-file-input>-->

            <validated-file-input placeholder="" label="Upload Video" name="Video" class="c-file-input"
                                  v-model="model.video" :disabled="loading">
                <template #append>
                    <div class="form-file-input-tag fl-x-cc text-white px-3">
                        <p class="m-0">Upload</p>
                        <img src="../../../assets/web/dashboard/icons/arrow-up-16.png" alt="">
                    </div>
                </template>
            </validated-file-input>

        <validated-input placeholder="" label="Order" class="c-form-input" type="number"
                         v-model="model.order" :disabled="loading"/>

        <div class="text-right">
            <btn color="secondary" text="Save" :loading="loading" loading-text="Saving..."></btn>
        </div>

    </b-form>
</template>

<script>
import urls from '@/data/urls';
// import CustomVideoFileInput from '@components/ui/CustomVideoFileInput';

export default {
    name : 'AddPropertyVideo',

    // components : { CustomVideoFileInput },

    data () {
        return {
            addUrl : urls.auction.auction.auctionVideo.addEdit
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully added Property Video', 'Success', { type : 'success' });
            this.$emit('success');
        }
    }

};
</script>

<style scoped>

</style>
