<template>
    <b-form class="row" :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}"
            :save-params="{auction: $route.params.id}">

        <div class="col-12 col-lg-12">
            <validated-input label="Item Name*" v-model="model.item_name" name="Bank name"
                             :rules="{required: true}" :disabled="loading"></validated-input>
        </div>

        <div class="col-12 col-lg-4">
            <validated-input :label="'Total Item Numbers' + (!is_third_party ? '*' : '')"
                             v-model="model.total_item_numbers" name="Total Item Numbers"
                             :rules="{required: !is_third_party}" :disabled="loading" type="number"></validated-input>
        </div>

        <div class="col-12 col-lg-4">
            <validated-vue-select :label="'State' + (!is_third_party ? '*' : '')" v-model="model.state" name="State"
                                  @input="setDistrict(model)" :rules="{required: !is_third_party}" :disabled="loading"
                                  :options="stateOptions"></validated-vue-select>
        </div>

        <div class="col-12 col-lg-4">
            <validated-vue-select :label="'District' + (!is_third_party ? '*' : '')" v-model="model.district"
                                  :disabled="loading" name="District" :rules="{required: !is_third_party}"
                                  :options="districtOptions"></validated-vue-select>
        </div>

        <div class="col-12 col-lg-4">
            <validated-input name="Pincode" :label="'Pincode' + (!is_third_party ? '*' : '')" v-model="model.pincode"
                             :rules="{required: !is_third_party}" :disabled="loading"></validated-input>
        </div>

        <div class="col-12 col-lg-4">
            <validated-input label="Location Latitude" v-model="model.item_location_lattitude"
                             :rules="{required: false}" :disabled="loading"/>
        </div>

        <div class="col-12 col-lg-4">
            <validated-input label="Location Longitude" v-model="model.item_location_longitude"
                             :rules="{required: false}" :disabled="loading"/>
        </div>

        <div class="col-12 col-lg-12">
            <validated-file-input placeholder="Item Data Sheet" label="Item Data Sheet" v-model="model.item_data_sheet"
                                  :disabled="loading" class="c-file-input">
                <template #append>
                    <div class="form-file-input-tag fl-x-cc text-white px-3">
                        <p class="m-0">Upload</p>
                        <img src="../../../assets/web/dashboard/icons/arrow-up-16.png" alt="">
                    </div>
                </template>
            </validated-file-input>
        </div>

        <div class="col-12">
            <div class="fl-x fl-j-c mt-4">
                <div class="btn-group">
                    <btn text="Add Item" :loading="loading" loading-text="Adding..." class="px-3"
                         design="basic-a" color="secondary"></btn>
                </div>
            </div>

        </div>
    </b-form>
</template>

<script>
import urls from '@/data/urls';
import { mapGetters } from 'vuex';
import axios from 'secure-axios';

export default {
    name : 'AddAuctionItem',

    props : {
        is_third_party : {
            type    : Boolean,
            default : false
        }
    },

    computed : {
        ...mapGetters(['currentUser'])
    },

    data () {
        return {
            addUrl : urls.auction.auction.auctionItem.addEdit,

            loading : false,
            error   : false,

            stateOptions       : [],
            districtOptions    : [],
            allDistrictOptions : [],
            categoryOptions    : [
                { label : 'Property', value : 'Property' },
                { label : 'Gold', value : 'Gold' },
                { label : 'Land', value : 'Land' }
            ]
        };
    },

    mounted () {
        this.loadData();
    },

    methods : {
        loadData () {
            const that = this;
            that.loading = true;
            that.error = false;
            axios.get(urls.auction.auction.auctionItem.formMount + '?type=Text').then(function (response) {
                const json = response.data;
                that.stateOptions = json.state.data;
                that.districtOptions = json.district.data;
                that.allDistrictOptions = json.district.data;
            }).catch(function (exception) {
                console.log('exception at the incident details loading => ', exception);
                that.loading = false;
                that.error = true;
            });
        },

        formSuccess () {
            this.$notify('Successfully added Auction Item', 'Success', { type : 'success' });
            this.$emit('success');
        },

        setDistrict (model) {
            model.district = '';
            const filterValues = this.allDistrictOptions.filter(obj => obj.state === model.state);
            this.districtOptions = [...filterValues];
        }
    }
};
</script>

<style scoped>

</style>
