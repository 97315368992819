<template>

    <b-form ref="form" :save-url="addUrl" :save-params="{auction: $route.params.id}"
            @success="formSuccess" v-slot="{model, loading}">

        <h4 class="text-center font-inter-semi-bold mb-5">Add Image</h4>

        <validated-file-input placeholder="" label="Upload Image" class="c-file-input" id="imgInp"
                              v-model="model.image" name="Image" :disabled="loading">
            <template #append>
                <div class="form-file-input-tag fl-x-cc text-white px-3">
                    <p class="m-0">Upload</p>
                    <img src="../../../assets/web/dashboard/icons/arrow-up-16.png" alt="">
                </div>
            </template>
        </validated-file-input>

        <validated-input placeholder="" label="Order" class="c-form-input" type="number"
                              v-model="model.order" :disabled="loading" />

        <div class="text-right">
            <btn color="secondary" text="Add" :loading="loading" loading-text="Adding..."></btn>
        </div>

    </b-form>

</template>

<script>
import urls from '@/data/urls';

export default {
    name : 'AddAuctionImage',

    data () {
        return {
            addUrl : urls.auction.auction.auctionImage.addEdit
        };
    },

    methods : {

        formSuccess () {
            this.$notify('Successfully added Property Image', 'Success', { type : 'success' });
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
